import trackerApi from "../api/tracker";
import { navigate } from "gatsby";

export const addArticle = async ({ title, description, author, date, image, categorie, link }) => {
  const user = window.localStorage.getItem("gatsbyUser");
  const token = JSON.parse(user).token;
  try {
    const Article = await trackerApi.post(
      "/addArticle/",
      {
        token,
        title,
        description,
        author,
        date,
        image,
        categorie,
        link,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return Article.data;
  } catch (error) {
    console.log("error can't create a Article : ", error);
  }
};

export const getAllArticles = async () => {
  try {
    const response = await trackerApi.get("/getArticles");
    return response.data;
  } catch (error) {
    console.log("error to get all Articles :", error);
  }
};

export const getArticleById = async (id) => {
  const user = window.localStorage.getItem("gatsbyUser");
  const token = JSON.parse(user).token;

  try {
    const Article = await trackerApi.get(`/getArticleById/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
      params: { token, id },
    });
    return Article.data;
  } catch (error) {
    console.log("error can't get a Article : ", error);
  }
};

export const updateArticleById = async ({ articleId, title, description, author, date, image, categorie, link }) => {
  const user = window.localStorage.getItem("gatsbyUser");
  const token = JSON.parse(user).token;

  try {
    await trackerApi.post(
      "/updateArticle/",
      {
        articleId,
        title,
        description,
        author,
        date,
        image,
        categorie,
        link,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    navigate("/app/allArticles");
  } catch (error) {
    console.log("ERROR can't update a Article");
  }
};

export const deleteArticle = async ({ articleId }) => {
  const user = window.localStorage.getItem("gatsbyUser");
  const token = JSON.parse(user).token;

  try {
    await trackerApi.post(
      "/deleteArticle/",
      {
        articleId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    navigate("/app/allArticles");
  } catch (error) {
    console.log("ERROR can't delete Article");
  }
};

export const addArticleImg = async ({ imgEventsBase64, articleId }) => {
  const user = window.localStorage.getItem("gatsbyUser");
  const token = JSON.parse(user).token;

  try {
    const addImageSelected = await trackerApi.post(
      "/imgArticle",
      {
        token,
        imgEventsBase64,
        articleId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log("addImageSelected :", addImageSelected);
  } catch (error) {
    console.log("ERROR can't add image for article");
  }
};

//get articles categorie count
export const getArticlesCategorieCount = async () => {
  const user = window.localStorage.getItem("gatsbyUser");
  const token = JSON.parse(user).token;

  try {
    const articlesCategorieCount = await trackerApi.get(`/getArticlesCategorieCount`, {
      headers: { Authorization: `Bearer ${token}` },
      params: { token },
    });
    console.log("articlesCategorieCount :", articlesCategorieCount);
    return articlesCategorieCount.data;
  } catch (error) {
    console.log("error to get articles categorie count :", error);
  }
}

//get articles by categorie
export const getArticlesByCategorie = async (categorie) => {
  const user = window.localStorage.getItem("gatsbyUser");
  const token = JSON.parse(user).token;

  try {
    const articlesCategorie = await trackerApi.get(`/getArticlesByCategorie/${categorie}`, {
      headers: { Authorization: `Bearer ${token}` },
      params: { token, categorie },
    });
    console.log("articlesCategorie :", articlesCategorie);
    return articlesCategorie.data;
  } catch (error) {
    console.log("error to get articles by categorie :", error);
  }
}

//search articles
export const searchArticles = async (search) => {
  const user = window.localStorage.getItem("gatsbyUser");
  const token = JSON.parse(user).token;

  try {
    const articlesSearch = await trackerApi.get(`/searchArticles/${search}`, {
      headers: { Authorization: `Bearer ${token}` },
      params: { token, search },
    });
    console.log("articlesSearch :", articlesSearch);
    return articlesSearch.data;
  } catch (error) {
    console.log("error to search articles :", error);
  }
}